import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
const Input = React.forwardRef(({ label, onChange, errorLabels, isValid, className, endDecorator, onBlur, ...props }, ref) => {
    const [hasBlurred, setHasBlurred] = useState(false);
    function handleOnBlur(event) {
        setHasBlurred(true);
        onBlur && onBlur(event);
    }
    return (_jsxs("div", { className: "mb-2", children: [_jsxs("div", { className: `flex items-center ${className}`, children: [label && _jsx("label", { className: " text-sm font-normal text-gray-900", children: label }), _jsx("div", { className: "absolute right-2 grayscale opacity-50", children: endDecorator }), isValid && hasBlurred && (_jsx("div", { className: `absolute ${endDecorator ? 'right-14' : 'right-2'} opacity-50  w-5 h-5`, children: _jsx(CheckIcon, { color: "#15803d" }) })), ' ', _jsx("input", { ref: ref, className: `w-full text-sm p-2 border border-gray-200 rounded ${!isValid ? 'border-red-600 dark:border-red-500' : ''} ${isValid && hasBlurred && 'border-green-700 dark:border-green-700'}`, onChange: event => onChange(event.target.value), onBlur: handleOnBlur, onFocus: () => setHasBlurred(false), ...props })] }), !isValid && _jsx("p", { className: "text-xs text-red-600 dark:text-red-500", children: errorLabels })] }));
});
export default Input;
