const enTable = {
    availability: 'Availability',
    groupSize: 'Group size',
    price: 'Price',
    errorTitle: 'Something went wrong with your payment',
    errorDesc: 'Please, check if the information you entered is correct, or check the card status with your bank.',
    goBackToCheckout: 'Go back to checkout',
    address: 'Address',
    addressNumber: 'Address number',
    zipCode: 'Post/ZIP code',
    city: 'City',
    country: 'Country',
    selectACountry: 'Select a country...',
    secureCardInfo: 'Secure credit card information',
    nameOnCard: 'Name on card',
    cardNumber: 'Card number',
    expirationDate: 'Expiration date',
    securityCode: 'Security code',
    youWillPreAuthorize: 'You will pre-authorize a charge of',
    wontBeChargedYet: "Don't worry! You won't be charged yet. You will be charged only if the guide confirms his availability.",
    asPerToRemainingBalance: 'As per to the remaining balance',
    automaticallyCharged: 'it will be automatically charged to your card 45 days prior to your tour start date.',
    haveToTransfer: 'you will have to transfer to us according to the instructions below.',
    personalInformation: 'Personal information',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    phoneNumber: 'Phone Number',
    instructionsForBankTransfer: 'Instructions for bank transfer',
    payeeName: 'Payee Name',
    reference: 'Reference',
    ach: 'ACH routing number',
    accountNumber: 'Account number',
    type: 'Type',
    wireNumber: 'Wire Number',
    bankAddress: 'Bank address',
    localUsTransfer: 'Local US Transfers',
    checkings: 'Checkings',
    international: 'International',
    US: 'United States of America',
    bankCode: 'Bank code',
    belgium: 'Belgium',
    austria: 'Austria',
    netherlands: 'Netherlands',
    spain: 'Spain',
    italy: 'Italy',
    finland: 'Finland',
    france: 'France',
    greece: 'Greece',
    ireland: 'Ireland',
    portugal: 'Portugal',
    germany: 'Germany',
    uk: 'United Kingdom',
    switzerland: 'Switzerland',
    norway: 'Norway',
    sweden: 'Sweden',
    poland: 'Poland',
    pay75daysLater: (currency, totalAmountToChargeLater) => `As per to the remaining balance (${currency} ${totalAmountToChargeLater}) you will have to transfer to us according to the instructions above. You must do so no later than 45 days before your tour start date, otherwise the tour will be canceled.`,
    wireNotice: 'If you choose to pay by bank transfer, your booking will not be confirmed until you send the receipt of payment via our booking management system. You will receive an email with instructions on how to do this.',
    payInFull: 'Pay in full',
    payLessUpfront: 'Pay less upfront',
    ccNowRestLater: 'with credit card now, the rest later',
    howPaymentWillOccur: 'How payment will occur:',
    youHaveChosen: 'You have chosen:',
    choosePaymentMethod: 'Choose your payment method',
    choosePaymentMethodForRemaining: 'Choose your payment method for the remaining amount',
    forRemainingAmount: 'For the remaining amount',
    chooseWheterToPay: 'choose whether to pay by credit card or bank transfer.',
    creditCard: 'Credit card',
    charged45DaysBeforeDP: 'will be charged 45 days before your departure',
    toBePaid45DaysBeforeDP: 'to be paid 45 days before your departure',
    noExtraFees: 'No extra fees',
    continueToKlarna: 'Continue to Klarna',
    youWillPayATotal: 'You will pay a total of',
    inNInstallmentsOf: 'divided into {{installments}} installments',
    payInNInstallments: 'Pay in {{installments}} installments',
    bankTransfer: 'Bank transfer',
    weWillProvideInstructions: 'We will provide detailed instructions by email',
    readAndAccept: "I've read and accept the",
    termsAndConditions: 'Terms & Conditions of Use',
    paymentError: 'Payment error',
    billingInformation: 'Billing Information',
    securePaymentInfo: 'Secure payment information',
    howToPay: 'How would you like to pay?',
    chooseHowToPay: 'Choose how to pay',
    requestToBook: 'Request to book',
    paymentMethodFee: 'Payment method fee',
    paymentProcessingFee: 'Payment processing fee',
    changePaymentMethod: 'Change payment method',
    changePayment: 'Change payment',
    confirmAndPay: 'Confirm details and pay',
    paymentInfo: 'Payment Information',
    otherPaymentMethods: 'Other payment methods (via Stripe)',
    paymentPoweredByStripe: 'Secure online payment powered by Stripe',
    pricePerPerson: 'Price per person',
    travellers: 'Travellers',
    serviceFee: 'Service fee',
    creditCardFee: 'Credit card fee',
    stripeFee: 'Stripe fee',
    total: 'Total',
    subTotal: 'Subtotal',
    thankYouPage: {
        title: 'Thank you!',
        reservationCode: 'Reservation code:',
        trip: 'Trip:',
        departureDate: 'Departure date:',
        returnToMyDashboard: 'Go to my dashboard',
        updateBillingInfo: 'Update my billing information',
        goToTripPage: 'Go to the trip page',
        wireInstructionsForDepositSent: 'The instructions to pay by wire transfer the left amount for this trip were sent by email. Contact our team if you need more information',
        wireInstructionsSent: 'The instructions to pay by wire transfer were sent by email. Contact our team if you need more information',
        otherOptions: 'Other options:',
        completeBillingDetails: 'Please, complete your billing details',
        saveBillingDetails: 'Save billing details',
        savingBillingDetails: 'Saving billing details...',
        savingBillingDetailsError: 'Error saving billing details',
        savingBillingDetailsSuccess: 'Billing details saved successfully',
        confirmationSentByEmail: "We've sent you the booking confirmation by e-mail.",
        seeBankDetails: 'See bank details',
        thankYouBilling: 'Thank you for completing your billing details, you can continue with:',
    },
    whetherToPay: 'For the remaining amount <strong>({{ price }})</strong> choose whether to pay by credit card or bank transfer.',
    payNow: 'Pay now',
    payLater: 'Pay later',
    tripDays: 'Trip duration (days)',
    secureCcInformation: 'Secure credit card information',
    now: 'now',
    succeededPayment: 'Payment processed successfully',
    processingPayment: 'Processing payment',
    paymentFailed: 'Error processing payment. Check your card details.',
    insufficient_funds: 'Error processing payment. Your card has insufficient funds.',
    generic_decline: 'Error processing payment. Your card has been declined.',
    lost_card: 'Error processing payment. Your card has been lost.',
    stolen_card: 'Error processing payment. Your card has been stolen.',
    do_not_honor: 'Error processing payment. Contact your card issuer to authorize the payment.',
    redirectingToStripe: 'Redirecting to Stripe...',
    errorRedirectingToStripe: 'Error redirecting to Stripe',
    instructionsWillBeSentByMail: 'The instructions will be sent to your email ({{email}})',
    changeCurrency: 'Change currency',
    closeDetails: 'Close details',
    showPricingDetails: 'Show pricing details',
    installment: 'Installment',
    priceMayVary: 'Please note that the price in your currency may vary depending on the exchange rate of the day.',
    guideCurrency: 'The currency of the guide is',
    finishBooking: 'Finish booking',
    confirmBooking: 'Confirm booking',
    pay: 'Pay',
    payPartNowPartLater: 'Pay part now, part later',
    dueToday: 'due today',
    dueLater: 'on {{date}}. No extra fees.',
    onDate: 'on {{date}}',
    payInInstallments: 'in installments',
    usingKlarna: 'Using Klarna',
    interestFree: 'interest-free',
    extraFee: 'Extra fee:',
    totalToPayNow: 'Total to pay now:',
    installmentAlreadyPaid: 'Installment already paid',
    invalidInstallment: 'This installment cannot be paid with credit card, please contact our sales team.',
    installmentNotFound: 'Installment not found',
    subtotalPerPerson: 'Subtotal per person ({{ percentage }}% deposit)',
    freeCancellation: '48-hour free cancellation with 30-day advance booking',
    askQuestions: 'Ask Questions',
    needAditionalServices: 'Need additional services?',
    accommodation: 'Find accommodation: Book Your Stay',
    insurance: 'Get covered: Travel & Rescue insurance',
    gear: 'Buy gear: Get the best from top brands',
    flights: 'Book flights: Flight bookings',
    car: 'Rent a car: Car rental services',
    exploreSafely: 'Explore the Mountain Safely: Learn Online',
    here: 'HERE',
};
export default enTable;
