const frTable = {
    availability: 'Disponibilité',
    groupSize: 'Nombre de personnes',
    price: 'Prix',
    errorTitle: 'Un problème est survenu lors de votre paiement',
    errorDesc: 'Veuillez vérifier que les informations que vous avez saisies sont correctes ou vérifiez le statut de la carte auprès de votre banque.',
    goBackToCheckout: 'Retour à la réservation',
    address: 'Adresse',
    addressNumber: 'Numéro',
    zipCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    selectACountry: 'Sélectionner un pays...',
    secureCardInfo: 'Sécuriser les informations relatives à la carte de crédit',
    nameOnCard: 'Nom sur la carte',
    cardNumber: 'Numéro de carte',
    expirationDate: 'Date d’expiration',
    securityCode: 'Code de sécurité',
    youWillPreAuthorize: 'Vous pré-autorisez un montant de',
    wontBeChargedYet: 'Ne vous inquiétez pas ! Vous ne serez pas encore débité. Vous ne serez facturé que si le guide confirme sa disponibilité.',
    asPerToRemainingBalance: 'Pour le solde restant',
    automaticallyCharged: 'il sera automatiquement débité de votre carte 45 jours avant la date de début de votre activité / séjour.',
    haveToTransfer: 'vous devrez nous transférer les fonds selon les instructions ci-dessous.',
    personalInformation: 'Informations personnelles',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    phone: 'Téléphone',
    phoneNumber: 'Numéro de téléphone',
    instructionsForBankTransfer: 'Instructions pour le virement bancaire',
    payeeName: 'Nom du bénéficiaire',
    reference: 'Référence',
    ach: 'ACH',
    accountNumber: 'Numéro de compte',
    type: 'Type',
    wireNumber: 'Numéro du transfer',
    bankAddress: 'Adresse bancaire',
    localUsTransfer: 'Virement local US',
    checkings: 'Chèques',
    international: 'International',
    US: "États-Unis d'Amérique",
    bankCode: 'code de la banque',
    belgium: 'Belgique',
    austria: 'Autriche',
    netherlands: 'Pays-Bas',
    spain: 'Espagne',
    italy: 'Italie',
    finland: 'Finlande',
    france: 'France',
    greece: 'Grèce',
    ireland: 'Irlande',
    portugal: 'Portugal',
    germany: 'Allemagne',
    uk: 'Royaume-Uni',
    switzerland: 'Suisse',
    norway: 'Norvège',
    sweden: 'Suède',
    poland: 'Pologne',
    pay75daysLater: (currency, totalAmountToChargeLater) => `Pour ce qui est du solde restant (${currency} ${totalAmountToChargeLater}) vous devrez nous le transférer selon les instructions ci-dessus. Vous devez le faire au plus tard 45 jours avant la date de début de votre voyage, sinon le voyage sera annulé`,
    wireNotice: "Si vous choisissez de payer par virement bancaire, votre réservation ne sera pas confirmée tant que vous n'aurez pas envoyé le reçu du paiement ici, via notre système de gestion des réservations. Vous recevrez un courriel contenant des instructions sur la manière de procéder.",
    payInFull: 'Payer en totalité',
    payLessUpfront: 'Payez moins au départ',
    ccNowRestLater: 'avec la carte de crédit maintenant, le reste plus tard',
    howPaymentWillOccur: 'Comment le paiement sera effectué:',
    youHaveChosen: 'Vous avez choisi:',
    choosePaymentMethod: 'Choisissez votre mode de paiement',
    choosePaymentMethodForRemaining: 'Choisissez votre mode de paiement pour le montant restant',
    forRemainingAmount: 'Pour le montant restant',
    chooseWheterToPay: 'choisir de payer par carte de crédit ou par virement bancaire.',
    creditCard: 'Carte de crédit',
    charged45DaysBeforeDP: 'sera prélevé 45 jours avant votre départ',
    toBePaid45DaysBeforeDP: 'à payer 45 jours avant votre départ',
    youWillPayATotal: 'Vous paierez un total de',
    bankTransfer: 'Virement bancaire',
    weWillProvideInstructions: 'Nous vous fournirons des instructions détaillées par courrier électronique',
    readAndAccept: "J'ai lu et j'accepte les",
    termsAndConditions: "Conditions d'utilisation",
    paymentError: 'Erreur de paiement',
    billingInformation: 'Informations sur la facturation',
    securePaymentInfo: 'Informations sur les paiements sécurisés',
    howToPay: 'Comment voulez-vous payer ?',
    requestToBook: 'Demander une réservation',
    chooseHowToPay: 'Choisissez comment payer',
    paymentMethodFee: 'Frais de paiement',
    paymentProcessingFee: 'Frais de traitement de paiement',
    changePaymentMethod: 'Modifier le mode de paiement',
    changePayment: 'Modifier le paiement',
    confirmAndPay: 'Confirmez les détails et payez',
    paymentInfo: 'Information sur le paiement',
    otherPaymentMethods: 'Autres méthodes de paiement (via Stripe)',
    paymentPoweredByStripe: 'Paiement en ligne sécurisé assuré par Stripe',
    pricePerPerson: 'Prix par personne',
    travellers: 'Voyageurs',
    continueToKlarna: 'Continuer avec Klarna',
    inNInstallmentsOf: 'En {{installments}} versements de',
    payInNInstallments: 'Payer en {{installments}} versements',
    serviceFee: 'Frais de service',
    creditCardFee: 'Frais de carte de crédit',
    stripeFee: 'Frais de Stripe',
    total: 'Total',
    subTotal: 'Sous-total',
    thankYouPage: {
        title: 'Merci!',
        reservationCode: 'Code de réservation:',
        trip: 'Voyage:',
        departureDate: 'Date de départ:',
        returnToMyDashboard: 'Accéder à mon tableau de bord',
        updateBillingInfo: 'Mettre à jour mes informations de facturation',
        goToTripPage: 'Aller à la page du voyage',
        wireInstructionsForDepositSent: "Les instructions pour payer par virement bancaire le montant restant pour ce voyage ont été envoyées par email. Contactez notre équipe si vous avez besoin de plus d'informations",
        wireInstructionsSent: "Les instructions pour payer par virement bancaire ont été envoyées par e-mail. Contactez notre équipe si vous avez besoin de plus d'informations",
        otherOptions: 'Autres options:',
        completeBillingDetails: 'Veuillez compléter vos coordonnées de facturation',
        saveBillingDetails: 'Enregistrer les données de la facturation',
        savingBillingDetails: 'Enregistrement des données de facturation...',
        savingBillingDetailsError: "Erreur lors de l'enregistrement des données de facturation",
        savingBillingDetailsSuccess: 'Les données de facturation ont été enregistrées avec succès',
        confirmationSentByEmail: 'Nous vous avons envoyé la confirmation de réservation par e-mail.',
        seeBankDetails: 'Voir les détails de la banque',
    },
    whetherToPay: 'Pour le montant restant ({{ price }}), vous pouvez choisir de payer par carte de crédit ou par virement bancaire.',
    payNow: 'Payer maintenant',
    payLater: 'Payer plus tard',
    tripDays: 'Durée du voyage (jours)',
    secureCcInformation: 'Sécuriser les informations relatives aux cartes de crédit',
    now: 'maintenant',
    succeededPayment: 'Paiement traité avec succès',
    processingPayment: 'Traitement du paiement',
    paymentFailed: 'Erreur dans le traitement du paiement. Vérifiez les détails de votre carte.',
    insufficient_founds: "Erreur dans le traitement du paiement. Votre carte n'est pas suffisamment approvisionnée",
    generic_decline: 'Erreur dans le traitement du paiement. Votre carte a été refusée',
    lost_card: 'Erreur dans le traitement du paiement. Votre carte a été perdue ou volée',
    stolen_card: 'Erreur dans le traitement du paiement. Votre carte a été perdue ou volée',
    do_not_honor: "Erreur de traitement du paiement. Contactez l'émetteur de votre carte pour autoriser le paiement.",
    redirectingToStripe: 'Redirection vers Stripe...',
    errorRedirectingToStripe: 'Erreur de redirection vers Stripe',
    instructionsWillBeSentByMail: 'Les instructions seront envoyées à votre adresse électronique ({{email}}).',
    changeCurrency: 'Changement de devise',
    closeDetails: 'Détails rapprochés',
    showPricingDetails: 'Afficher les détails des prix',
    installment: 'Acompte',
    priceMayVary: 'Veuillez noter que le prix dans votre devise peut varier en fonction du taux de change du jour.',
    guideCurrency: 'La devise du guide est',
    finishBooking: 'Terminer la réservation',
    confirmBooking: 'Confirmer la réservation',
    pay: 'Payer',
    payPartNowPartLater: 'Payer une partie maintenant, une partie plus tard',
    dueToday: 'dû aujourd’hui',
    dueLater: 'le {{date}}. Pas de frais supplémentaires.',
    onDate: 'le {{date}}',
    payInInstallments: 'en plusieurs fois',
    noExtraFees: 'Pas de frais supplémentaires',
    usingKlarna: 'Avec Klarna',
    interestFree: 'sans intérêt',
    extraFee: 'Frais supplémentaires:',
    totalToPayNow: 'Total à payer maintenant',
    installmentAlreadyPaid: 'Paiement déjà effectué',
    invalidInstallment: 'Ce versement ne peut pas être payé par carte de crédit, veuillez contacter notre équipe de vente',
    installmentNotFound: "Le versement n'a pas été trouvé",
    subtotalPerPerson: "Total partiel par personne ({{ percentage }}% d'acompte) ",
    freeCancellation: "Annulation gratuite de 48 heures avec une réservation de 30 jours à l'avance",
    askQuestions: 'Poser des questions',
    needAditionalServices: 'Avez-vous besoin de services supplémentaires ?',
    accommodation: 'Trouver un logement : Réservez votre séjour',
    insurance: 'Take Cover : Assurance voyage et sauvetage',
    gear: 'Acheter du matériel : le meilleur des grandes marques',
    flights: 'Réserver des vols : Réservation de vols',
    car: 'Louer une voiture : Services de location de voitures',
    exploreSafely: 'Explorez la montagne en toute sécurité : Apprendre en ligne',
    here: 'ICI',
};
export default frTable;
